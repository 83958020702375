import $ from "jquery";
globalThis.jQuery = $;

// GENERAL STYLING
import './styles.scss';

// FONT AWESOME
import { library, dom  } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
library.add(faInstagram, faFacebookF, faArrowDown, faChevronDown);
dom.watch();

// SLICK SLIDER
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

// SKROLLR
import skrollr from 'skrollr';


(function ($) {

  $(function () {

    'use strict';

    // DOM ready, take it away

    // MOBILE MENU submenu
    $('<div class="subarrow"><i class="fa-solid fa-chevron-down"></i></div>').insertAfter('.full-menu li.menu-item-has-children > a');
    $('.full-menu .subarrow').on('click', function () {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
      }
    });

    // SKROLLR
// ===============================================
    var s = skrollr.init({
      forceHeight: false
    });

    if (s.isMobile()) {
      s.destroy();
    }


    // MOBILE TOGGLE
    // ===============================================

    $('.menu-toggle').on('click', function () {
      if ($('.full-menu').hasClass('active')) {
        $('.full-menu, .page-overlay, .menu-toggle').removeClass('active');
        $('body').removeClass('overflow-hidden');
      } else {
        $('.full-menu, .page-overlay, .menu-toggle').addClass('active');
        $('body').addClass('overflow-hidden');
      }
    });
    $('.page-overlay').on('click', function () {
      $('.full-menu, .page-overlay, .menu-toggle').removeClass('active');
      $('body').removeClass('overflow-hidden');
    });

    $('.full-menu ul li a, .header .logo').on('click', function () {
      $('body').removeClass('overflow-hidden');
    });

    // Close mobile menu on click
    $(document).click(function (event) {
      var $target = $(event.target);
      if (!$target.closest('.full-menu').length && !$target.closest('.menu-toggle').length && $('.full-menu').hasClass('active')) {
        $('.full-menu').removeClass('active');
        $('body').removeClass('overflow-hidden');
      }

    });

    // Slick slider
    // ===============================================
    $('.sponsors-slider').slick({
      slidesToShow: 9,
      slidesToScroll: 3,
      infinite: false,
      dots: false,
      arrows: false,
      responsive: [
              {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
              breakpoint: 991,
              settings: {
                  slidesToShow: 5,
                  slidesToScroll: 2,
              }
          },
          {
            breakpoint: 676,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
          },
        ]
      });

    // Scrollbar calc for tailwind breakout
    // ===============================================
    $('html').css('--twcb-scrollbar-width', (window.innerWidth - $('html').width()) + "px");

    // Fancybox
    // ===============================================
    Fancybox.bind("[data-fancybox]", {});
    $('.wp-block-gallery a').attr('data-fancybox', 'gallery');

    // HEADER SCROLLED
    // ===============================================
    checkScroll();
    $(window).on('scroll', function() {
        checkScroll();
    });
    
    function checkScroll() {
        var scroll = $(window).scrollTop();
        if (scroll >= 60) {
            $("header, body").addClass("scrolled");
        } else if (scroll < 40) {
            $("header, body").removeClass("scrolled");
        }
    }
    


    AOS.init({
      duration: 400,
      offset: 0, // offset (in px) from the original trigger point
      once: true, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
    });
    window.addEventListener('load', AOS.refresh);


  });

})(jQuery);